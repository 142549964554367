<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.getters.darkMode }">
    <portal-target name="login"/>
    <CWrapper class="wrapper">
      <Header/>
      <LineRunner v-if="$store.getters['user/getPermissions']('view_run_line') && showLineRunner" />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid class="container-custom d-flex flex-column">
            <router-view></router-view>

            <portal-target v-if="isBrave" name="brave-notify-modal"/>
          </CContainer>
        </main>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import Header from "@/components/Header";
import LineRunner from "@/components/LineRunner";
import { mapState } from "vuex";
export default {
  name: "General",
  components: {
    Header,
    LineRunner,
  },
  computed: {
    ...mapState({
      isBrave: state => state.browserNotify.isBrave,
      showLineRunner: state => state.lineRunner.showLineRunner,
    }),
  },
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";
.wrapper {
  @include media-breakpoint-up(lg) {
    height: 100vh;
    height: 100dvh;
  }
}
.c-body,
.c-main,
.container-custom {
  @include media-breakpoint-up(lg) {
    overflow-y: hidden;
    height: 100%;
  }
}

.c-main {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}
.container-custom {
  padding: 0;

  @include media-breakpoint-up(xl) {
    padding: 0 2.5rem;
    max-width: 87.5rem;
  }
}
</style>
